@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800&family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,400;1,500;1,600;1,700;1,800&display=swap');

:root{
  --themeDarkBlueColor:#00A7E2;
  --themeLightColor:#00A7E2;
  --themeBorderDevider:#CCD5EA;
  --themeBorder: #C3C6CA;
  --themeCellDraft:#FFEDC6;
  --themeCellbg:#F3F6FE;
  --themeStripSubHeader:#D4E1FD;
  --themeSectionBg:#F2F5FC;
  --themeBody:#F2F5FC;
  --defaultPadding:24px;
  --h3default:24px;
  --defaultInputCorner:8px;
  --themeFont: 'Poppins', sans-serif !important;
  --defaultBlockPadding:24px;
  --brandShadow:rgba(0, 0, 0, 0.231372549) 1px 0px 10px 0px;
  --chipDefaultbg:#E0E7F9;
  --chipHoverBG:#ededed;
  --colorBlack:#000;
  --addressPermanent:#D8EBB1;
  --addressType:#26a8ea;
  --addressTemp:#DEE4ED;
  --textUppercase:uppercase;
  --colorWhite:#FFF;
  --mediumGray:#77787B;
  --red:#fa3f3f; 
  --defaultFont-size:14px;
  --fontweightbold:600;
  --tableshadowWithoutBorder:0px 1px 8px 0px rgba(0, 0, 0, 0.15);
  --lightbg:#fcfcfc ;
  --success:#47bfea;
  --error: #fa7d7d;
  --warning:#FDB415;
  --info:#1779C6;
  --alert:rgb(229, 246, 253);
  --inter: 'Inter', sans-serif;
  --label:#202124;
  --interBlck:#202124;
  --bodyBg:#f4f4f4;
  --topbarShadow:0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  --bodybg:#f4f4f4;
  --listInfoBg:#3E5DA3;
  --successDarkColor:rgb(46, 125, 50);
  --profileBg:#8EB6D7;
}

h2{
  font-size: var(--h3default);
  margin: 0;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
h3{
  font-size: 20px;
  color: #000;
  margin: 0;
}
h4{
  color: var(--colorWhite);
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
h5{
  color: var(--colorBlack);
  font-size: 16px;
  font-weight:600;
  margin: 0;
}
h6{
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 16px 0;
  line-height: 24px;
  font-weight: bold;
}
body{
    padding: 0;
    margin: 0; 
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500;
    background:var(--colorWhite);
    font-size: var(--defaultFont-size) !important;
  }
  .MuiToolbar-root{
    background-color: #1A2D57 !important;
  }

p.MuiTypography-root:not(:root){
  font-family: 'Poppins', sans-serif !important;
}
a{
  color: #0E7EBC !important;
  font-weight: 500;

  & p{
    color: #0E7EBC !important;
  }
}
ul:not(:root) {
  color: #4E5F83;
}
.MuiPaper-root:not(:root){
  top: 64px;
}
:global(.MuiTypography-root){
  font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    font-size: var(--defaultFont-size) !important;
    color: #000 ;
}
:global(.MuiDataGrid-virtualScroller ){
  border-top: solid 1px #3e78bc;
  & :global(.MuiDataGrid-row:nth-of-type(odd)){
    background: #F3F6FE;
  }
  & :global(.MuiDataGrid-row:nth-of-type(even)){
    background: var(--colorWhite);
  }
}
button, select, label{
  font-family: 'Poppins', sans-serif !important;
  margin: 0;
  font-weight: 600 !important;
  font-size: var(--defaultFont-size) !important;
  color: #202124;
  text-transform: none !important;
}

select, input{
  background: var(--colorWhite) !important;
  border: #C3C6CA solid 1px !important;
  border-radius: var(--defaultInputCorner);
  padding:8px 12px !important;
  //  width: 100%;
  font-size: var(--defaultFont-size) !important;
  text-transform: uppercase;
}
:global(.MuiInputBase-root){
  border-radius: var(--defaultInputCorner) !important;
}
:global(.MuiSelect-select){
  background: var(--colorWhite) !important;
  border-radius: 8px !important;
  padding: 8px 11px 7px 11px !important;
  font-size: var(--defaultFont-size) !important;
  height: 23px !important;
  line-height: 23px !important;
  text-transform: uppercase;

  & :global(.Mui-disabled){
    background-color: #f1f1f1 !important;
    color: #646464 !important;
    -webkit-text-fill-color: #646464 !important;
  }
}
:global(.MuiDataGrid-cellContent){
  color: #000;
 }
input:focus-visible, input:focus-visible{
  border: 0;
  outline:#C3C6CA ; 
}

:global(.MuiFormLabel-root.Mui-focused) {
  color: var(--themeDarkBlueColor) !important;
}
:global(button) {
  & svg{
height: 21px !important;
  }
}
:global(.MuiButton-text){
  & svg{
margin-right: 4px;
margin-left: 4px;
}
}

:global(.MuiFormLabel-root){
  color: #000 !important;
  padding:0 0 5px 0px !important;
}
:global(.MuiFormControl-root){
  margin:  0px 0 5px 0 !important;
  font-family: var(--themeFont) !important;
  font-size: 14px !important;
  text-transform: uppercase;
}
:global(.MuiInputBase-root ){
  margin:  0px 0 5px 0 !important;
  font-family: var(--themeFont) !important;
  font-size: 14px !important;
  text-align: left !important;
}
:global(.MuiDataGrid-main){
  background: var(--colorWhite);
  border-radius: 8px;
  box-shadow: #0000001c 1px 1px 9px 0px;
}
.addLink {
  color: #3E78BC;
  font-size: var(--defaultFont-size);
  text-decoration: underline;
  font-weight: 600;
}

hr{
  border: #d3d3d385 solid 1px;
  height: 1px;
  width: 100%;
  margin: 24px 0;
}
:global(.MuiTextField-root){
  margin: 0px 0px !important;
}
:global(.MuiGrid-item){
padding-top: 0px !important;
}
:global(.MuiButton-outlined){
  border: #1A2D57 solid 1px !important;
  color: var(--themeDarkBlueColor) !important;
}
:global(.MuiButton-containedPrimary){
  background-color: var(--themeLightColor);
}
:global(.MuiFormLabel-root sup){
  color: var(--red);
  font-weight: bold;
  font-size: 15px;
}
:global(.MuiFormHelperText-root.Mui-error){
  margin: 0px;
  color: var(--red) !important;
 font-size: 13px;
 font-family: var(--themeFont) !important;
 white-space: break-spaces;
}
:global(.MuiInput-root:before) {
  border-bottom: 0 !important;
  }
/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px; 
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
:global(.drawerShinked){
& :global(.MuiListItemText-root){
 opacity: 0;
  transform: translateY(0);
  animation-delay: 0.2ms;
  transition: all 0.2s linear, margin 0s linear 0s;
  }

  & :global(.msubMenus){
    display: none;
  }
  
  & :global(.msubMenusOpen){
  display: none;
}

}
:global(.hysiap){
  overflow: inherit !important;
}
:global(.mt_0){
  margin-top: 0 !important;
}
:global(.mb_0){
  margin-bottom: 0 !important;
}

:global(.mt_9){
  margin-top: 10px;
}
:global(.MuiFormGroup-root){
  margin-top: -8px;
  & :global(.MuiFormControlLabel-label){
    font-weight: 400 !important;
    font-size: 14px;
    font-family: var(--inter) !important;
    padding-right: 2px !important;
    direction: initial;
  }
  & :global(.MuiRadio-root){
    padding-right: 4px;
  }
}
:global(.MuiSwitch-root){
  width: 48px !important;
  height: 24px !important;
  border-radius: 12px !important;
  background: #B0C1D6;
  border: #1A2D57 1px solid;

  & :global(.MuiSwitch-thumb){
    width: 18px !important;
    height: 18px !important;
    border-radius: 50% !important; 
  }
  :global(.MuiSwitch-switchBase) {
    padding: 2px 0 0 4px !important;
}
:global(.MuiSwitch-switchBase.Mui-checked) {
  transform: translateX(24px) !important;
}
:global(.MuiSwitch-switchBase.Mui-checked +.MuiSwitch-track){
  background-color:#3E78BC !important;
}
}
:global(.saveSectionDefault){
  padding:24px 32px 24px 32px;
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 682px;
  background: #FFF;
}
:global(.sidebarOpen){
  padding-left: 0px !important;
  padding:0 0 0 15px !important;
  transition: 0.3s;
}
:global(.sidebarClose){
  padding:0 0 0 66px !important ;
  transition: 0.3s;
}
:global(.MuiFormHelperText-root) {
  color: var(--red) !important;
  margin: 0px !important;
  font-size: 13px !important;
}
:global(.MuiLink-root) {
  text-decoration-color:#00A7E2 !important;
  cursor: pointer;
}

:global(.close-btn) {
  position: fixed;
  top: 0px;
  right: -10px;
  margin: 0rem 0.5rem;
  & button svg{
    color: black;
 }
}
.vis-ltr .vis-label:not(.vis-nesting-group):not(.vis-group-level-0) {
  padding-left: 15px !important;
  background: #E9F8FF !important;
}
.testclss{
  background: #00BA69 !important;
}
:global(.vis-item-content p){
  font-family: var(--inter) !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  margin-top: 0 !important;
  margin-bottom: 0px !important;

  & :global(span){
    color: red;
  }
}
:global(.vis-item-content span){
display: flex !important;
font-family: var(--inter) ;
font-size: 14px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: normal;
gap: 8px !important;
align-items: center;
}
:global(.vis-item .vis-item-content) {
  padding: 2px 3px !important;
}

:global(.MuiDialog-paperScrollPaper){
  min-width: 460px !important;
}
:global(.MuiDialog-paper){
border-radius: 12px !important;
}
:global(.InlinePopover){
  overflow-y: unset !important;
  overflow-x: unset !important;
}
.deviderSection{
  margin:24px 0 ;
}

.websiteInput{
  text-transform: none !important;

  & input {
      text-transform: none !important;
  }
}
td.stickyAvatar{
  margin: 0;

  & :global(.MuiBox-root){
margin-top: 10px;
  }
}

.cardBlock{
  background: white;
  margin: 0;
  padding: var(--defaultBlockPadding);
  margin: 24px 0px 0 0px;
  border-radius: var(--defaultInputCorner);
  box-shadow: var(--brandShadow);
  & h4{
      color: var(--colorBlack);
      font-size: 20px;
      font-weight: 600;
      margin: 0;
  }
}

:global(.MuiRadio-root:hover){
  background-color: transparent !important;
}
.fl-right{
  float: right;
}
:global(.MuiMenuItem-root){
  white-space: break-spaces !important;
}

:global(.msubMenus){
  width: 100%;

  & ul{
    padding: 0;

  }

  & li{
    color: #FFF;
    border-bottom: rgba(123, 123, 123, 0.4) solid 1px;
    padding-left: 10px;
    width: 100%;
    padding: 7px 7px 7px 35px;
    display: flex;
    align-items: center;
    gap: 5px;

    & svg{
      font-size: 12px;
    }
  }

  & a{
    color: #FFF !important;
    font-weight: normal !important;
  }

  & .active{ 
    background: none !important;
  }
}
:global(.msubMenus ul li a.active){
background: none !important;
}

.msubMenusToggle{
  width: 100%;
  display: flex;

  & ul{
    padding: 0;
    margin-top: -8px;



  & li{
    color: #FFF;
    border-bottom: rgba(123, 123, 123, 0.4) solid 1px;
    padding-left: 10px;
    width: 100%;
    padding: 7px 7px 7px 35px;
    display: flex;
    align-items: center;
    gap: 5px;

   & :hover{
      background-color:#00a0d9;
    }

    & svg{
      font-size: 12px;
      color: #FFF;
    }
  }

  & a{
    color: #FFF !important;
    font-weight: normal !important;

    &:hover{
      color: #000 !important;
    }
  }
}
}
input:disabled {
  color: #000 !important;
  background-color: #f1f1f1 !important;
  color: #646464 !important;
  -webkit-text-fill-color: #646464 !important;
}
:global(.MuiSelect-outlined) {
  & :global(.Mui-disabled){
  background-color: #f1f1f1 !important;
  color: #646464 !important;
    -webkit-text-fill-color: #646464 !important;
    color: #646464 !important;
    -webkit-text-fill-color: #646464 !important;
}
}

:global(.MuiDrawer-modal){
  //z-index: 9999 !important;
}
:global(.ck-editor__editable){
  min-height: 200px !important;
}

:global(.disabled-btn) {
  color: #9e9e9e !important;
  cursor: not-allowed;
}


:global(.MuiOutlinedInput-root){
  padding: 0 !important;
}
:global(.MuiAutocomplete-popper){
position: absolute;
z-index: 9999;
}
.docView{
  padding: 0px !important;
  min-width: 10px !important;
}
:global(.ant-picker-range){
  padding: 0 6px 0px 0;
  border-color: #cccccc !important;
  width: 100%;

  & :global(.ant-picker-input input){
    border: none !important;
    padding: 7px !important;
  }

}
:global(.vis-custom-time) {
  pointer-events: none;
} 

:global(.MuiAutocomplete-root .MuiInputBase-inputAdornedStart){
  border: none !important;

  & :global(.MuiChip-labelMedium){
      max-width: 130px;
  }
}
:global(.MuiAutocomplete-root){
  & :global(.MuiChip-labelMedium){
      max-width: 130px;
  }
}

 :global(.MuiMenu-paper){
  max-width: 400px !important;
}
::placeholder{
  color: #767676 !important;
  opacity: 1 !important;
}
input::placeholder{
  color: #767676 !important;
  opacity: 1 !important;
}
input::-webkit-input-placeholder {
  color: #767676 !important;
}
input::-moz-placeholder {
  color: #767676 !important;
}
input:-ms-input-placeholder {
  color: #767676 !important;
}
input:-moz-placeholder {
  color: #767676 !important;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; /* Remove the default appearance */
  height: 17px; /* Set desired height */
  width: 17px; /* Set desired width */
  background-image: url('../../FrontEnd/public/close.svg'); /* Use a custom icon if desired */
  background-size: cover; /* Ensure the custom icon covers the element */
  background-repeat: no-repeat; /* Prevent the custom icon from repeating */
  cursor: pointer; /* Change cursor to pointer for better UX */
}


//:global(.vis-timeline){
//height: 80vh !important;
//}
//:global(.vis-left.vis-panel.vis-vertical-scroll) {
//  height:80vh !important;
//}



@media print {
  .hide-placeholder input::placeholder {
    color: transparent;
  }
}

:global(.download-pdf){
  border: none !important;
}
:global(.MuiDialog-root){
  z-index: 1201 !important;
}

div#txt-renderer {
  white-space: break-spaces;
}
:global(div#proxy-renderer) {
  overflow: inherit;
}

:global(.MuiAutocomplete-popupIndicator){
  & :global(.MuiSvgIcon-root) {
    fill: rgb(145 142 142 / 54%);
  }
}